import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import { RadioGroup } from '@emico-react/input-radio'
import { globalWindow } from '@emico-utils/ssr-utils'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { GetStaticProps } from 'next'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import DefaultLayout from '../components/DefaultLayout'
import NextLink from '../components/NextLink'
import RadioCustom from '../components/RadioCustom'
import RadioCustomButton from '../components/RadioCustomButton'
import { addApolloState, initializeApollo } from '../shared/apollo/apolloClient'
import { Card } from '../shared/card/Card'
import { CardConverter } from '../shared/card/CardConverter'
import { CardGrid } from '../shared/card/CardGrid'
import { Choices, Data } from '../shared/context/ConfiguratorContext'
import { hashProduct } from '../shared/hash/utils'
import { getInverterModels } from '../shared/inverter/get'
import { InverterModel } from '../shared/inverter/InverterModel'
import { InverterType } from '../shared/inverter/InverterType'
import { getProduct } from '../shared/product/get'
import { Product } from '../shared/product/product'
import { getSolarPanels } from '../shared/solar-panel/get'
import Container from '../shared/ui/Container'
import { IncrementButton } from '../shared/ui/IncrementButton'
import theme from '../theme'

const env = process.env.REACT_APP_ENV

const StyledNextLink = styled(NextLink)`
  cursor: pointer;
  color: ${theme.colors.primary};
  font-weight: bold;

  &:not(:last-child) {
    margin-right: 16px;

    &:after {
      content: '|';
      color: ${theme.colors.gray};
      margin-left: 16px;
    }
  }
`

const StyledContainer = styled(Container)`
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
`

const ConverterWrapper = styled.div`
  display: flex;
`

const StyledRadioGroup = styled(RadioGroup)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`

const Block = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 48px;
`

const SmallCol = styled.div`
  width: 25%;
`

const WideCol = styled.div`
  width: 75%;
`

function pp(obj: unknown) {
  return JSON.stringify(obj, null, 2)
}

interface Props {
  choices: Choices
  data: DemoData
  locale: string | undefined
}

interface DemoData extends Data {
  solarPanels: Product[]
}

interface FormFields extends Choices {
  count: number
  radio: string
  accordion1: number
  accordion2: number
  monitoring: string
}

const Index = ({
  choices: initialChoices,
  data: initialData,
  locale,
}: Props) => {
  const activeStoreView = useActiveStoreView()

  const [data] = useState<DemoData>(initialData)
  const { register, watch, control } = useForm<FormFields>({
    defaultValues: {
      ...initialChoices,
      count: 0,
      accordion1: 0,
      accordion2: 0,
    },
  })

  const formData = watch()

  if (env !== 'dev') {
    return null
  }

  return (
    <DefaultLayout pageType="configurator" showFooter={false}>
      <StyledContainer>
        <h1>Development Page</h1>
        <h2>Links</h2>

        <Block>
          <StyledNextLink
            href="/configurator/start"
            analyticsContext=""
            analyticsName=""
          >
            Start
          </StyledNextLink>

          <StyledNextLink
            href={`/configurator/?h=${data.solarPanels[0].hash}`}
            analyticsContext=""
            analyticsName=""
          >
            Configurator
          </StyledNextLink>

          <StyledNextLink
            href="/configurator/overview"
            analyticsContext=""
            analyticsName=""
          >
            Overview
          </StyledNextLink>

          <StyledNextLink
            href="/checkout/shipping"
            analyticsContext=""
            analyticsName=""
          >
            Checkout
          </StyledNextLink>
        </Block>

        <h2>App</h2>

        <Block>
          <WideCol>
            <h3>Store views</h3>

            <pre>{`
Active StoreView code in Window: ${pp(globalWindow?.activeStoreViewCode)}

Active StoreView:
${pp(activeStoreView)}
            `}</pre>
          </WideCol>
        </Block>

        <h2>Components</h2>

        <Block>
          <SmallCol>
            <h3>ProductCard</h3>

            <span>Component currently used only in the start step.</span>
            <br />
          </SmallCol>
          <WideCol>
            {data.solarPanels[0] ? <Card item={data.solarPanels[0]} /> : null}
          </WideCol>
        </Block>

        <Block>
          <SmallCol>
            <h3>ProductCardGrid</h3>

            <span>Component currently used only in the start step</span>
            <br />

            <h5>Depends on:</h5>
            <ul>
              <li>ProductCard</li>
            </ul>
          </SmallCol>
          <WideCol>
            <CardGrid items={data.solarPanels} />
          </WideCol>
        </Block>

        <Block>
          <SmallCol>
            <h3>ProductCardSlim</h3>

            <span>
              Component used in the configurator to show a product which the
              user can increment their quantity from.
            </span>
            <br />

            <h5>Depends on:</h5>
            <ul>
              <li>IncrementButton</li>
            </ul>
          </SmallCol>
          <WideCol>
            {/* <CardSlim
              name="acMaterials"
              control={control}
              item={data.solarPanels[0]}
            /> */}
          </WideCol>
        </Block>

        <Block>
          <SmallCol>
            <h3>ProductCardConverter</h3>

            <span>
              Component used to show a converter to the user and either select
              it or show the details from.
            </span>
          </SmallCol>
          <WideCol>
            {data.inverterModels[0] ? (
              <CardConverter
                item={data.inverterModels[0]}
                active={formData.inverterModel === data.inverterModels[0].id}
                controlName="inverterModel"
                control={control}
              />
            ) : null}
          </WideCol>
        </Block>

        <Block>
          <SmallCol>
            <h3>IncrementButton</h3>

            <span>
              Simple component which makes it possible to have a button which
              increments with a label
            </span>
          </SmallCol>
          <WideCol>
            <IncrementButton
              label="Increment"
              value={formData.count}
              name="count"
              control={control}
            />
          </WideCol>
        </Block>

        <Block>
          <SmallCol>
            <h3>RoofPanelsOptions</h3>

            <span>
              Component which makes it possible in the configurator to configure
              the positions of the solar panels.
            </span>
            <br />

            <h5>Depends on:</h5>
            <ul>
              <li>IncrementButton</li>
            </ul>
          </SmallCol>
          <WideCol>{/* <RoofPanelsOptions /> */}</WideCol>
        </Block>

        <Block>
          <SmallCol>
            <h3>RadioCustom - Default</h3>

            <span>Simple custom radio button</span>
          </SmallCol>

          <WideCol>
            <ConverterWrapper>
              <RadioGroup label="test buttons" showLabel={false}>
                <RadioCustom value="a">
                  <span>Option A</span>
                </RadioCustom>

                <RadioCustom value="b">
                  <span>Option B</span>
                </RadioCustom>

                <RadioCustom value="c">
                  <span>Option C</span>
                </RadioCustom>
              </RadioGroup>
            </ConverterWrapper>
          </WideCol>
        </Block>

        <Block>
          <SmallCol>
            <h3>RadioCustomButton - Custom select state</h3>

            <span>Custom radio button in styled button</span>
          </SmallCol>

          <WideCol>
            <ConverterWrapper>
              <StyledRadioGroup
                label="test buttons custom"
                showLabel={false}
                defaultValue="micro"
              >
                <RadioCustomButton
                  register={register}
                  radioGroupName="testButtonsCustom"
                  value="micro"
                >
                  <span>
                    <Trans>Micro</Trans>
                  </span>
                </RadioCustomButton>

                <RadioCustomButton
                  register={register}
                  radioGroupName="testButtonsCustom"
                  value="string"
                >
                  <span>
                    <Trans>String</Trans>
                  </span>
                </RadioCustomButton>

                <RadioCustomButton
                  register={register}
                  radioGroupName="testButtonsCustom"
                  value="hybrid"
                  isDisabled
                >
                  <span>
                    <Trans>Hybrid</Trans>
                  </span>
                </RadioCustomButton>
              </StyledRadioGroup>
            </ConverterWrapper>
          </WideCol>
        </Block>

        <Block>
          <SmallCol>
            <h3>ConverterTypeOptions</h3>

            <span>
              The component that is used in the configurator to choose the
              inverter type.
            </span>
            <br />

            <h5>Depends on:</h5>
            <ul>
              <li>ConverterRadioButton</li>
            </ul>
          </SmallCol>
          <WideCol>
            <InverterType />
          </WideCol>
        </Block>

        <Block>
          <SmallCol>
            <h3>ConverterModelOptions</h3>
            <span>
              Component which makes it possible for the user to choose a
              inverter model.
            </span>{' '}
            <br />
            <br />
            <span>
              (Showing the models is possible by making a choice in the
              ConverterTypeOptions component)
            </span>
            <br />
            <h5>Depends on:</h5>
            <ul>
              <li>ProductCardConverter</li>
            </ul>
          </SmallCol>
          <WideCol>
            <InverterModel locale={locale} />
          </WideCol>
        </Block>

        <Block>
          <SmallCol>
            <h3>Accordion</h3>

            <span>
              Accordion component which can be reused in other components
            </span>
          </SmallCol>
        </Block>
      </StyledContainer>
    </DefaultLayout>
  )
}

export const getStaticProps: GetStaticProps = async ({
  locale,
  defaultLocale,
}) => {
  const client = initializeApollo(locale ?? defaultLocale ?? '')

  const data: DemoData = {
    solarPanel: null,
    solarPanels: [],
    elektramatModel: null,
    inverterTypes: [],
    inverterModel: null,
    inverterModels: [],
    inverterMonitors: [],
    inverterStorages: [],
    acMaterials: [],
    accessories: [],
    mountingEsdec: [],
    mountingCPX: [],
  }

  const choices = {
    solarPanel: '1547',
    solarPanelPositions: [
      {
        displayName: t({ message: 'North-West' }),
        windDirection: 'north-west',
        quantity: 0,
      },
      {
        displayName: t({ message: 'North' }),
        windDirection: 'north',
        quantity: 0,
      },
      {
        displayName: t({ message: 'North-East' }),
        windDirection: 'north-east',
        quantity: 0,
      },
      {
        displayName: t({ message: 'West' }),
        windDirection: 'west',
        quantity: 0,
      },
      {
        displayName: t({ message: 'East' }),
        windDirection: 'east',
        quantity: 0,
      },
      {
        displayName: t({ message: 'South-West' }),
        windDirection: 'south-west',
        quantity: 0,
      },
      {
        displayName: t({ message: 'South' }),
        windDirection: 'south',
        quantity: 0,
      },
      {
        displayName: t({ message: 'South-East' }),
        windDirection: 'south-east',
        quantity: 0,
      },
    ],
    elektramatModel: '',
    inverterType: '',
    inverterModel: '',
  }

  data.solarPanel = await getProduct(client, '1547', locale)

  const solarPanels = await getSolarPanels(client, locale)

  data.solarPanels = await Promise.all(
    solarPanels.map(
      async (solarPanel) => await hashProduct(client, solarPanel),
    ),
  )

  data.inverterModels = await getInverterModels(
    client,
    locale,
    '1547',
    choices.solarPanelPositions,
  )

  return addApolloState(client, {
    props: {
      data,
      choices,
      locale,
    },
    revalidate: 60,
  })
}

export default Index
